import React from 'react';

const auxillary = (props) => {
    return(
        <div {...props}>
            {props.children}
        </div>
    )
};

export default auxillary;