import React from 'react';

import Auxillary from '../../hoc/Auxillary';
import classes from './AuthLayout.module.css';
import Logo from '../../assets/logo.png';

const AuthLayout = (props) => {
    var style;
    if (props.height) {
        style = { height: props.height };
    }
    return (
        <Auxillary>
            <header className={classes.header}>
                <img src={Logo} alt="ITK Logo" />
            </header>
            <div className={classes.container}>
                <div className={classes.formContainer} style={style}>
                    {props.children}
                </div>
            </div>

        </Auxillary>
    )
}

export default AuthLayout;