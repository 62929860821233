import React from "react";
import classes from "./Input.module.css";

const input = (props) => {
  let inputElement = "";

  switch (props.elementType) {
    case "text":
      inputElement = (
        <input
          className={classes.input}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case "password":
      inputElement = (
        <input
          className={classes.input}
          type="password"
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    default:
      inputElement = (
        <input
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }
  return (
    <div className={classes.fieldContainer}>
      <span className={classes.label}>{props.text}</span>
      {inputElement}
    </div>
  );
};

export default input;
